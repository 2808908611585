@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  overflow: hidden;
  font: 14px "Century Gothic", Futura, sans-serif;
  min-width: 100vw;
  min-height: 100vh;
  /* background-color: #607D8B; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game {
  /* padding: 20px; */
  background-image: url("../public/texture/lave.jpg");
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease-out;
}

ol,
ul {
  padding-left: 30px;
}

.board-row {
  display: flex;
  flex-wrap: nowrap;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}




.square:focus {
  outline: none;
}

.square {
  background: #fff;
  /* border: 0px solid #c43004; */
  float: left;
  /* font-size: 15px; */
  font-weight: bold;
  line-height: 100px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 100px;
  font-size: 12px;
  color: #607d8b;
  position: relative;
}




.w_r3 {
  border-right: 16px solid rgb(114 31 7);
}

.w_l3 {
  border-left: 16px solid rgb(114 31 7);
}

.w_t3 {
  border-top: 16px solid rgb(114 31 7);
}

.w_b3 {
  border-bottom: 16px solid rgb(114 31 7);
}



.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.game-board {
  position: absolute;
  transition: all 0.3s ease-out;
}



.fa {
  background-size: contain;
  transition: all 0.8s;
}

.fa1 {
  background-image: url("../public/texture/fa1.png");
}

.fa2 {
  background-image: url("../public/texture/fa2.png");
}

.fa3 {
  background-image: url("../public/texture/fa3.png");
}




.fa1.endtop {
  background-image: url("../public/texture/fa1.png");
}

.fa2.endtop {
  background-image: url("../public/texture/fa2.png");
}





.visited0.fa1 {
  background-image: url("../public/texture/fa10.png") !important;
}

.visited0.fa2 {
  background-image: url("../public/texture/fa20.png") !important;
}

.visited0.fa3 {
  background-image: url("../public/texture/fa30.png") !important;
}


.visited2.fa1 {
  background-image: url("../public/texture/fa12.png");
}

.visited2.fa2 {
  background-image: url("../public/texture/fa22.png");
}

.visited2.fa3 {
  background-image: url("../public/texture/fa32.png");
}





.visited0.fa.randomtptrue:before {
  content:none;
}

.fa.randomtptrue:before {
  content:"";
  background-image: url("../public/texture/randomtp.png");
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  left: 25%;
  top: 25%;
}

.visited2.fa.randomtptrue:before {
  content:"";
  background-image: url("../public/texture/randomtp_dark.png");
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  left: 25%;
  top: 25%;
}

.visited0.fa.randomtptrue:before {
  content:none;
}





.visited0.fa.hidetrue:before {
  content:none;
}

.fa.hidetrue:before {
  content:"";
  background-image: url("../public/texture/hide.png");
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  left: 25%;
  top: 25%;
}

.visited2.fa.hidetrue:before {
  content:"";
  background-image: url("../public/texture/hide_dark.png");
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  left: 25%;
  top: 25%;
}

.visited0.fa.hidetrue:before {
  content:none;
}




.visited0.fa.melangeurtrue:before {
  content:none;
}

.fa.melangeurtrue:before {
  content:"";
  background-image: url("../public/texture/melange.png");
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  left: 25%;
  top: 25%;
}

.visited2.fa.melangeurtrue:before {
  content:"";
  background-image: url("../public/texture/melange_dark.png");
  width: 50%;
  height: 50%;
  background-size: contain;
  position: absolute;
  left: 25%;
  top: 25%;
}

.visited0.fa.melangeurtrue:before {
  content:none;
}




.visited0.fa.open1:before {
  content:none;
}

.fa.open1:before {
  content:"";
  background-image: url("../public/texture/open.png");
  width: 43%;
  height: 51%;
  position: absolute;
  left: 23px;
  top: -40px;
  z-index: 6;
  background-size: contain;
  background-repeat: no-repeat;
}

.visited2.fa.open1:before {
  content:"";
  background-image: url("../public/texture/open_dark.png");
  width: 43%;
  height: 51%;
  position: absolute;
  left: 23px;
  top: -40px;
  z-index: 6;
  background-size: contain;
  background-repeat: no-repeat;
}



.visited0.fa.endbottomright:before {
  content:none;
}

.fa.endbottomright:before {
  content:"";
  background-image: url("../public/texture/down.png");
  transform: rotate(180deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.visited2.fa.endbottomright:before {
  content:"";
  background-image: url("../public/texture/down_dark.png");
  transform: rotate(180deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.visited0.fa.endtopright:before {
  content:none;
}
.fa.endtopright:before {
  content:"";
  background-image: url("../public/texture/down.png");
  transform: rotate(90deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.visited2.fa.endtopright:before {
  content:"";
  background-image: url("../public/texture/down_dark.png");
  transform: rotate(90deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.visited0.fa.endbottomleft:before {
  content:none;
}
.fa.endbottomleft:before {
  content:"";
  background-image: url("../public/texture/down.png");
  transform: rotate(270deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.visited2.fa.endbottomleft:before {
  content:"";
  background-image: url("../public/texture/down_dark.png");
  transform: rotate(270deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}


.w {
  background-repeat: repeat;
  background-size: contain;
}


.w_l1 .ww_l {
  background-image: url("../public/texture/w_cote.png");
  width: 11px;
  height: calc(100% + -1px);
  position: absolute;
  top: -18px;
  left: -4px;
  z-index: 6;
}

.w_t1 .ww_t {
  background-image: url("../public/texture/w_face.png");
  width: calc(100% + 9px);
  height: 49px;
  position: absolute;
  top: -27px;
  left: -4px;
  z-index: 4;
}

.w_b1 .ww_b {
  background-image: url("../public/texture/w_face_dark2.png");
  width: calc(100% + 9px);
  height: 49px;
  position: absolute;
  bottom: -21px;
  left: -4px;
  z-index: 4;
}

.w_r1 .ww_r {
  background-image: url("../public/texture/w_cote.png");
  width: 11px;
  height: calc(100% + -1px);
  position: absolute;
  top: -18px;
  right: -6px;
  z-index: 6;
  
}


.visited2.w_l1 .ww_l {
  background-image: url("../public/texture/w_cote_dark.png");
  z-index: 4;
}

.visited2.w_t1 .ww_t {
  background-image: url("../public/texture/w_face_dark.png");
  z-index: 3;
}

.visited2.w_b1 .ww_b {
  background-image: url("../public/texture/w_face_dark.png");
  z-index: 3;
}

.visited2.w_r1 .ww_r {
  background-image: url("../public/texture/w_cote_dark.png");
  z-index: 4;
}

.row_wall_top .w_l1 .ww_l,
.row_wall_top .w_r1 .ww_r {
  /* height: calc(100% + 43px);
  top: -63px; */
  height: calc(100% + 31px);
    top: -48px;
  background-position-y: bottom;
}

.row_wall_bottom .w_l1 .ww_l,
.row_wall_bottom .w_r1 .ww_r {
  height: calc(100% + 2px);
}


.square .ww_l,
.square .ww_t,
.square .ww_b,
.square .ww_r {
  transition: all 0.1s;
}

.visited0.w_l1 .ww_l,
.visited0.w_t1 .ww_t,
.visited0.w_b1 .ww_b,
.visited0.w_r1 .ww_r, 
.visited0.w_l3 .ww_l,
.visited0.w_t3 .ww_t,
.visited0.w_b3 .ww_b,
.visited0.w_r3 .ww_r {
  opacity: 0;
}

.visited1.w_l1 .ww_l,
.visited1.w_t1 .ww_t,
.visited1.w_b1 .ww_b,
.visited1.w_r1 .ww_r, 
.visited1.w_l3 .ww_l,
.visited1.w_t3 .ww_t,
.visited1.w_b3 .ww_b,
.visited1.w_r3 .ww_r {
  opacity: 1;
}



.w_l3 .ww_l {
  background-image: url("../public/texture/w_cote.png");
  width: 20px;
  height: calc(100% + 8px);
  position: absolute;
  top: -27px;
  left: -6px;
  z-index: 16;

}

.w_t3 .ww_t {
  background-image: url("../public/texture/w_face.png");
  width: calc(100% + 11px);
  height: 73px;
  position: absolute;
  top: -63px;
  left: -6px;
  z-index: 4;
}

.w_b3 .ww_b {
  background-image: url("../public/texture/w_face_dark2.png");
  width: calc(100% + 14px);
  height: 75px;
  position: absolute;
  bottom: -59px;
  left: -6px;
  z-index: 4;
}

.w_r3 .ww_r {
  background-image: url("../public/texture/w_cote.png");
  width: 20px;
  height: calc(100% + 8px);
  position: absolute;
  top: -27px;
  right: -8px;
  z-index: 16;
}


.visited2.w_l3 .ww_l {
  background-image: url("../public/texture/w_cote_dark.png");
  z-index: 14;
}

.visited2.w_t3 .ww_t {
  background-image: url("../public/texture/w_face_dark.png");
  z-index: 3;
}

.visited2.w_b3 .ww_b {
  background-image: url("../public/texture/w_face_dark.png");
  z-index: 3;
}

.visited2.w_r3 .ww_r {
  background-image: url("../public/texture/w_cote_dark.png");
  z-index: 14;
}

.row_wall_top .w_l3 .ww_l,
.row_wall_top .w_r3 .ww_r {
  height: calc(100% + 45px);
  top: -62px;
  background-position-y: top;
}

.row_wall_bottom .w_l3 .ww_l,
.row_wall_bottom .w_r3 .ww_r {
  height: calc(100% + 14px);
}






.logob1 {
  background-image: url("../public/chara/chara_b0.png");
  animation: identifiera 0.5s;
}

.logob2 {
  background-image: url("../public/chara/chara_b0.png");
  animation: identifierb 0.5s;
}

.player {
  width: 29px;
  height: 51px;
  z-index: 2;
  border-radius: 50px;
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% - 6px);
  background-size: contain;
}

.logob1 {
  background-image: url("../public/chara/chara_b0.png");
  animation: identifierb1 0.3s;
}

.logob2 {
  background-image: url("../public/chara/chara_b0.png");
  animation: identifierb2 0.3s;
}

@keyframes identifierb1 {
  0% {
    background-image: url("../public/chara/chara_b0.png");
  }

  25% {
    background-image: url("../public/chara/chara_b1.png");
  }

  50% {
    background-image: url("../public/chara/chara_b0.png");
  }

  75% {
    background-image: url("../public/chara/chara_b2.png");
  }

  100% {
    background-image: url("../public/chara/chara_b0.png");
  }
}

@keyframes identifierb2 {
  0% {
    background-image: url("../public/chara/chara_b0.png");
  }

  25% {
    background-image: url("../public/chara/chara_b1.png");
  }

  50% {
    background-image: url("../public/chara/chara_b0.png");
  }

  75% {
    background-image: url("../public/chara/chara_b2.png");
  }

  100% {
    background-image: url("../public/chara/chara_b0.png");
  }
}






.logot1 {
  background-image: url("../public/chara/chara_t0.png");
  animation: identifiert1 0.3s;
}

.logot2 {
  background-image: url("../public/chara/chara_t0.png");
  animation: identifiert2 0.3s;
}

@keyframes identifiert1 {
  0% {
    background-image: url("../public/chara/chara_t0.png");
  }

  25% {
    background-image: url("../public/chara/chara_t1.png");
  }

  50% {
    background-image: url("../public/chara/chara_t0.png");
  }

  75% {
    background-image: url("../public/chara/chara_t2.png");
  }

  100% {
    background-image: url("../public/chara/chara_t0.png");
  }
}

@keyframes identifiert2 {
  0% {
    background-image: url("../public/chara/chara_t0.png");
  }

  25% {
    background-image: url("../public/chara/chara_t1.png");
  }

  50% {
    background-image: url("../public/chara/chara_t0.png");
  }

  75% {
    background-image: url("../public/chara/chara_t2.png");
  }

  100% {
    background-image: url("../public/chara/chara_t0.png");
  }
}








.logor1 {
  background-image: url("../public/chara/chara_r0.png");
  animation: identifierr1 0.3s;
}

.logor2 {
  background-image: url("../public/chara/chara_r0.png");
  animation: identifierr2 0.3s;
}

@keyframes identifierr1 {
  0% {
    background-image: url("../public/chara/chara_r0.png");
  }

  25% {
    background-image: url("../public/chara/chara_r1.png");
  }

  50% {
    background-image: url("../public/chara/chara_r0.png");
  }

  75% {
    background-image: url("../public/chara/chara_r2.png");
  }

  100% {
    background-image: url("../public/chara/chara_r0.png");
  }
}

@keyframes identifierr2 {
  0% {
    background-image: url("../public/chara/chara_r0.png");
  }

  25% {
    background-image: url("../public/chara/chara_r1.png");
  }

  50% {
    background-image: url("../public/chara/chara_r0.png");
  }

  75% {
    background-image: url("../public/chara/chara_r2.png");
  }

  100% {
    background-image: url("../public/chara/chara_r0.png");
  }
}










.logol1 {
  background-image: url("../public/chara/chara_l0.png");
  animation: identifierl1 0.3s;
}

.logol2 {
  background-image: url("../public/chara/chara_l0.png");
  animation: identifierl2 0.3s;
}

@keyframes identifierl1 {
  0% {
    background-image: url("../public/chara/chara_l0.png");
  }

  25% {
    background-image: url("../public/chara/chara_l1.png");
  }

  50% {
    background-image: url("../public/chara/chara_l0.png");
  }

  75% {
    background-image: url("../public/chara/chara_l2.png");
  }

  100% {
    background-image: url("../public/chara/chara_l0.png");
  }
}

@keyframes identifierl2 {
  0% {
    background-image: url("../public/chara/chara_l0.png");
  }

  25% {
    background-image: url("../public/chara/chara_l1.png");
  }

  50% {
    background-image: url("../public/chara/chara_l0.png");
  }

  75% {
    background-image: url("../public/chara/chara_l2.png");
  }

  100% {
    background-image: url("../public/chara/chara_l0.png");
  }
}





.openmenu{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 21;

}
.openmenubtn{
  margin: 0px;
  padding: 10px;
  font-size: 21px;
  background-color: #262223;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  border: solid 3px #BF360C;
  cursor: pointer;
  font-family: 'MedievalSharp', cursive;
}
.openmenubtn:hover{
  background-color: #100f10;
  color: #BF360C;
}




.menu{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menutitre{
  margin: 10px;
  padding: 15px;
  font-size: 37px;
  color: #262223;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.menuniveau{
  margin: 10px 10px 30px;
  font-size: 30px;
  color: #BF360C;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.menuniveau .size{
  font-size: 18px;
  color: #262223;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 15px;
}


.menucontente{
  background-image: url("../public/texture/bg_menu_1.png");
  padding: 15px;
  font-family: 'MedievalSharp', cursive;
}

.menucontente .menubtn{
  margin: 10px;
  padding: 15px;
  font-size: 26px;
  background-color: #262223;
  color: #BF360C;
  text-align: center;
  text-transform: uppercase;
  border: solid 6px #262223;
  cursor: pointer;
}
.menucontente .menubtn:hover{
  background-color: #100f10;
  border: solid 6px #BF360C;
}





.boxcaracts{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.boxcaracts .itemcaracts{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 11px;
  flex: 1;
  width: 100px;
}

.boxcaracts .itemcaracts .case_melangeur{
    background-image: url("../public/texture/melange.png");
    width: 50px;
    height: 50px;
    background-size: contain;
}
.boxcaracts .itemcaracts .case_hide{
  background-image: url("../public/texture/hide.png");
  width: 50px;
  height: 50px;
  background-size: contain;
}
.boxcaracts .itemcaracts .case_randomtp{
  background-image: url("../public/texture/randomtp.png");
  width: 50px;
  height: 50px;
  background-size: contain;
}


.boxcaracts .txt{
  font-size: 14px;
  padding-top: 10px;
  font-weight: 600;
  color: #262223;
}

/* .arrow-up {
  width: 0;
  height: 0;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  border-bottom: 50vh solid red;
  position: absolute;
  top: 0px;
  z-index: 20;
  transform: rotate(180deg);
  right: 0px;
  opacity: 0.4;
}
 */


 /* .arrow-up {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 100px 50px;
  border-color: transparent transparent #000000 transparent;
} */

.arrow-up{
position: absolute;
    z-index: 20;
    pointer-events: none;
  }
.arrow-up polygon {
  stroke-width: 0;
  stroke: black;
  pointer-events: initial;
 fill-opacity: 0;
}
.arrow-up polygon:hover {
  stroke: red;
}

.arrow-down{
  position: absolute;
      z-index: 20;
      bottom: 0px;
      pointer-events: none;
    }
  .arrow-down polygon {
    stroke-width: 0;
    stroke: red;
    pointer-events: initial;
    fill-opacity: 0;
  }
  .arrow-down polygon:hover {
    stroke: blue;
  }
  

  .arrow-left{
    overflow: hidden;
    position: absolute;
        z-index: 20;
        bottom: 0px;
        pointer-events: none;
      }
    .arrow-left polygon {
      stroke-width: 0;
      stroke: green;
      pointer-events: initial;
      fill-opacity: 0;
    }
    .arrow-left polygon:hover {
      stroke: yellow;
    }
    

    .arrow-right{
      pointer-events: none;
      overflow: hidden;
      position: absolute;
          z-index: 20;
          bottom: 0px;
          right: 0px;
        }
      .arrow-right polygon {
        stroke-width: 0;
        stroke: orange;
        pointer-events: initial;
        fill-opacity: 0;
      }
      .arrow-right polygon:hover {
        stroke: blue;
      }
      
/* .arrow-down {
width: 0;
    height: 0;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    border-bottom: 50vh solid blue;
    position: absolute;
    bottom: 0px;
    z-index: 20;
    right: 0px;
    opacity: 0.4;
  }
  .arrow-left {
    width: 0;
    height: 0;
    border-top: 50vh solid transparent;
    border-bottom: 50vh solid transparent;
    border-right: 50vw solid yellow;
    position: absolute;
    top: 0px;
    z-index: 20;
    transform: rotate(180deg);
    left: 0px;
    opacity: 0.4;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 50vh solid transparent;
    border-bottom: 50vh solid transparent;
    border-right: 50vw solid green;
    position: absolute;
    top: 0px;
    z-index: 20;
    right: 0;
    opacity: 0.4;
  } */